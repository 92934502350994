/* eslint-disable no-unused-vars */
import {
  useAnimate,
  stagger,
  motion,
  useAnimation,
  useInView,
} from 'framer-motion';
import logo from './assets/2dicks3dimensions.png';

function App() {
  return (
    <>
      <div className="app">
        <motion.img
          className="logo"
          src={logo}
          initial={{ scale: 0.0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            duration: 5,
            ease: 'easeIn',
          }}
        ></motion.img>
        {/* <div className="socials">X</div> */}
      </div>
    </>
  );
}

export default App;
